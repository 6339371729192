@keyframes float {
	0% {
		transform: translatey(0px);
	}
	50% {
		transform: translatey(-20px);
	}
	100% {
		transform: translatey(0px);
	}
}

body {
  margin: 0;
  background: #160B30;
}

.contact {
  position: absolute;
  top: 2rem;
  right: 1rem;
  color: #160B30;
  border: 0;
  font-size: 1.2rem;
  font-weight: 400;
  font-family: 'Inter';
  background-color: #FFC727;
  border-radius: 3px;
  width: 10rem;
  height: 3rem;
}

.contact:hover {
  transform: scale(1.1);
  transition: 0.5s;
  cursor: pointer;
  background-color: #dfab1c;

}

.instagram {
  position: absolute;
  bottom: 2rem;
  right: 1.5rem;
  cursor: pointer;
}

.instagram:hover {
  transform: scale(1.1);
  transition: 0.5s;
}

.yello-logo {
  height: 5rem;
  pointer-events: none;

  position: absolute;
  top: 2rem;
  left: 3rem;
}

.description {
  z-index: 2;
  display: flex;
  flex-direction: column;
  
  width: 70%;
  position: absolute;
  top: 30vh;
}

.title {
  font-family: 'Inter';
  font-size: 3.2rem;
  font-weight: 700;
  margin: 0;
  padding: 0;
  text-align: left;
  margin-bottom: 1.5rem;
  color: #FFC727;
}

.paragraph {
  text-align: left;
  margin: 0;
  padding: 0;
  font-family: 'Inter';
  font-weight: 200;
  font-size: 2rem;
  color: #FAFAFA;
}


.body {
  width: 95%;
  margin: 0 auto;
  display: flex;
  flex-direction: row; 
  align-items: center;
}


.yello-banner {
  z-index: 1;
  height: 100vh;
  pointer-events: none;

  position: absolute;
  top: 0;
  right: 2%;
}

.yello-banner-floating-man {
  z-index: 2;
  position: absolute;
  top: 20vh;
  right: 9vw;
  width: 10%;
  pointer-events: none;
  transform: translatey(0px);
	animation: float 6s ease-in-out infinite;
}

.corpy {
  position: absolute;
  bottom: 1.5rem;
  left: 1rem;
  pointer-events: none;
  color: #fafafa;
  font-size: 1.2rem;
  font-weight: 100;
  font-family: 'Inter';
}

/* ################################################ */
/* MOBILE */
/* ################################################ */

@media screen and (max-width: 480px) {
  .instagram {
    bottom: 2rem;
    right: 0.5rem;
    cursor: pointer;
  }

  .contact {
    top: 0.7rem;
    right: 0.9rem;
  }

  .corpy {
    position: absolute;
    bottom: 1.3rem;
    left: 0.5rem;
    color: #fafafa;
    font-size: 0.8rem;
    width: 50%;
  }

  .yello-logo {
    position: absolute;
    top: 0rem;
    left: 0.5rem;
  }

  .body {
    flex-direction: column;
  }

  .yello-banner {
    display: none;
  }

  .yello-banner-floating-man  {
    top: 16rem;
    right: 2rem;
    width: 8rem;
  }

  .computer-banner-mobile {
    position: relative;
    top: 25rem;
    left: -5rem;
  }

  .description {
    width: 90%;

    position: absolute;
    top: 8rem;
    left: 0.5rem;
  }

  .title {
    font-size: 2rem;
    margin-bottom: 1rem;
    margin-top: 4rem;
  }

  .paragraph {
    font-size: 1.5rem;
    text-align: left;
    margin-top: 1rem;
  }
}